import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"

const PreviewButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
`

const LightboxDialog = styled(Dialog)`
  position: relative;
  width: 80vw;
  padding: 1rem;
}
`

export default class Lightbox extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      showLightbox: false,
      selectedImage: null,
    }
  }

  render() {
    const { images } = this.props
    const { selectedImage, showLightbox } = this.state
    return (
      <Fragment>
        <div className="lightboxContainer">
          {images.map((image, index) => {
            const img = getImage(image.node)
            return (
              <PreviewButton
                key={index}
                type="button"
                onClick={() =>
                  this.setState({ showLightbox: true, selectedImage: img })
                }
              >
                <GatsbyImage image={img} alt="Oasi 540" />
              </PreviewButton>
            )
          })}
        </div>
        {showLightbox && (
          <LightboxDialog
            onDismiss={() => this.setState({ showLightbox: false })}
          >
            <button
              type="button"
              className="closeButton"
              onClick={() => this.setState({ showLightbox: false })}
            >
              Close
            </button>
            <GatsbyImage image={selectedImage} alt="Oasi 540" />
          </LightboxDialog>
        )}
      </Fragment>
    )
  }
}
