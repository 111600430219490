import { Video } from "gatsby-video"
import React, { useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
// import TikTokAudio from "../components/tikTokAudio"
import VisibilitySensor from "react-visibility-sensor"

const TikTokVideos = props => {
  // const data = useStaticQuery(graphql`
  //   query Videos {
  //     allFile(
  //       filter: {
  //         extension: { regex: "/(mp4)/" }
  //         sourceInstanceName: { eq: "videos" }
  //         relativeDirectory: { eq: "tiktok" }
  //       }
  //     ) {
  //       edges {
  //         node {
  //           childVideoFfmpeg {
  //             webm: transcode(
  //               outputOptions: ["-crf 20", "-b:v 0"]
  //               maxHeight: 612
  //               fileExtension: "webm"
  //               codec: "libvpx-vp9"
  //             ) {
  //               width
  //               src
  //               presentationMaxWidth
  //               presentationMaxHeight
  //               originalName
  //               height
  //               fileExtension
  //               aspectRatio
  //             }
  //             mp4: transcode(
  //               maxHeight: 612
  //               fileExtension: "mp4"
  //               codec: "libx264"
  //             ) {
  //               width
  //               src
  //               presentationMaxWidth
  //               presentationMaxHeight
  //               originalName
  //               height
  //               fileExtension
  //               aspectRatio
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  // // Initiate the video pool
  // const videos = data.allFile.edges

  // // Create random indexes to use against the video pool
  // const indexes = []
  // const randomInt = (min, max) => {
  //   return Math.floor(Math.random() * (max - min + 1)) + min
  // }

  // // Grab three videos from the pool
  // while (indexes.length < 3) {
  //   let tempInt = randomInt(0, data.allFile.edges.length - 1)
  //   if (!indexes.includes(tempInt)) {
  //     indexes.push(tempInt)
  //   }
  // }

  // let tiktokVideos = []
  // videos.forEach(video => {
  //   const originalName = video.node.childVideoFfmpeg.webm.originalName
  //   const videoName =
  //     originalName.substr(0, originalName.lastIndexOf(".")) || originalName

  //   tiktokVideos[videoName] = video.node.childVideoFfmpeg
  // })

  // let video1, video2, video3
  // video1 = tiktokVideos["wildaf"]
  // video2 = tiktokVideos["framechasers"]
  // video3 = tiktokVideos["candyss.love"]

  // const audioRef = useRef(null)

  // // const onVisibilityChange = isVisible => {
  // //   if (isVisible) {
  // //     audioRef.current.showAudioControl()
  // //   } else {
  // //     if (!audioRef.current.isAudioPlaying) {
  // //       audioRef.current.hideAudioControl()
  // //     }
  // //   }
  // // }

  return (
    <section className="videos">
      <p className="videos__text videos__text--quote">
        “There's only one place I want to go and it's to all the places I've
        never been”{" "}<br/>
        <span className="videos__text videos__text--attr">
          -&nbsp;Nikki&nbsp;Rowe
        </span>
      </p>
      {/* <VisibilitySensor partialVisibility={true} onChange={onVisibilityChange}> */}
        <div className="tiktok">
          {/* <TikTokAudio ref={audioRef} /> */}
          <div className="tiktok__container">
            <div className="tiktok__video">
              {/* <Video
                id="tikTokVideo1"
                preload="auto"
                autoPlay
                muted
                loop
                playsInline
                sources={[video1.webm, video1.mp4]}
              /> */}
            </div>
          </div>
          <div className="tiktok__container">
            <div className="tiktok__video">
              {/* <Video
                id="tikTokVideo2"
                preload="auto"
                autoPlay
                muted
                loop
                playsInline
                sources={[video2.webm, video2.mp4]}
              /> */}
            </div>
          </div>
          <div className="tiktok__container">
            <div className="tiktok__video">
              {/* <Video
                id="tikTokVideo0"
                preload="auto"
                autoPlay
                muted
                loop
                playsInline
                sources={[video3.webm, video3.mp4]}
              /> */}
            </div>
          </div>
        </div>
      {/* </VisibilitySensor> */}
      <p className="videos__text videos__text--inspire">
        Now it's your turn...
      </p>
    </section>
  )
}

export default TikTokVideos
