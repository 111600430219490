import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ComingSoon = () => {
  const [email, setEmail] = useState("")
  const [result, setResult] = useState("")
  const [message, setMessage] = useState("")
  const [showFeedback, setShowFeedback] = useState(false)

  const { squirrelHeaderImg } = useStaticQuery(graphql`
    query {
      squirrelHeaderImg: file(relativePath: { eq: "squirrel-header-transparent.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, height: 100, layout: CONSTRAINED)
        }
      }
    }
  `)

  const imgHeader = getImage(squirrelHeaderImg)  

  return (
    <>
      <div
        role="alert"
        className={
          "toast toast--top toast--" +
          result +
          (showFeedback ? " toast--active" : "")
        }
      >
        <p>{message}</p>
      </div>

      <section className="home-content">

        <div className="home-content__header-img">
          <GatsbyImage image={imgHeader} alt="Squirrel" />
        </div>

        <div className="home-content__time-nsh">
          <span>9:24PM</span>
          <span>NSH</span>
        </div>

      </section>
    </>
  )
}

export default ComingSoon
