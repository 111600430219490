import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Lightbox from "./lightbox"

const OasiGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      oasiImages: allFile(
        filter: {
          extension: { regex: "/(jpg)/" }
          relativeDirectory: { eq: "oasi-grid" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                width: 1600
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `)

  const shuffle = o => {
    for (
      let j, x, i = o.length;
      i;
      j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x
    );
    return o
  }

  data.oasiImages.edges = shuffle(data.oasiImages.edges)

  return (
    <section className="grid-section">
      <h1 className="grid-section__heading">
        Introducing the Wingamm Oasi 540 aka Squirrel
      </h1>
      <p className="grid-section__body">
        The most compact, luxurious, technologically advanced motorhome in the
        world
      </p>
      <Lightbox images={data.oasiImages.edges} />
      <Link className="grid-section__link" to="/our-motorhomes">
        Learn About the Motorhome
      </Link>
    </section>
  )
}

export default OasiGrid
