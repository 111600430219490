import React from "react"

const Media = () => {
  return (
    <>
      <section className="media">
        <a href="https://www.gearpatrol.com/cars/a36619175/wingamm-oasi-540-rv-camper-van/">
          <img
            src="/media/gear-patrol-logo.png"
            alt="Gear Patrol: This Tiny RV Is a Whole New Type of Awesome Camper Van"
            width=""
          />
        </a>
        <a href="https://jalopnik.com/this-rv-is-smaller-than-a-pickup-truck-and-still-has-ev-1847094655">
          <img
            src="/media/jalopnik-logo.png"
            alt="Jalopnik: This RV Is Smaller Than A Pickup Truck And Still Has Everything You Need For A Getaway"
          />
        </a>
        <a href="https://newatlas.com/automotive/wingamm-oasi-540-micro-rv-united-states/">
          <img
            src="/media/new-atlas.png"
            alt="Italian-designed Wingamm micro RV could be America's favorite camper"
          />
        </a>      
      </section>
    </>
  )
}

export default Media
