import { graphql, useStaticQuery } from "gatsby"
import React, { useState }  from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const FunAF = () => {
  const [email, setEmail] = useState("")
  const [result, setResult] = useState("")
  const [message, setMessage] = useState("")
  const [showFeedback, setShowFeedback] = useState(false)

  const { funAFImage } = useStaticQuery(graphql`
    query {
      funAFImage: file(relativePath: { eq: "Layout-Ring.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, height: 1200, layout: CONSTRAINED)
        }
      }
    }
  `)

  const img = getImage(funAFImage)

  const _handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email)
      .then(data => {
        if (data.msg.includes("already subscribed")) {
          setMessage(email + " is already subscribed to this list")
        } else {
          setMessage(data.msg)
        }

        setResult(data.result)
        setShowFeedback(true)

        setTimeout(function () {
          setShowFeedback(false)
        }, 5000)
      })
      .catch(error => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      })
  }

  const _handleEmailChange = e => {
    setEmail(e.currentTarget.value)
  }

  return (
    <section className="fun-af">
      <GatsbyImage image={img} alt="funAF" />

      <div className="fun-af__notify">
        <form onSubmit={_handleSubmit}>
          <input
            type="text"
            value={email}
            placeholder="Keep Me Updated"
            className="fun-af__notify__input"
            onChange={_handleEmailChange}
          />
          <input
            type="submit"
            name="notify"
            className="fun-af__notify__button"
            value="Let's Go"
          />
          <label htmlFor="email" className="visually-hidden">
            Keep Me Updated
          </label>
        </form>
      </div>

    </section>

  )
}

export default FunAF
